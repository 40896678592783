<template>
  <div class="container">
    <div class="top">
      <img class="logo-icon" src="../assets/static/image/logo.png" />
      <img class="logo-font" src="../assets/static/image/font_logo.png" />
    </div>


    <div class="form">
      <!-- <el-button
        class="commonBtn btnBlueStyle"
        @click="useWechat"
        >企业微信授权登录</el-button
      > -->
      <div id="qrCode"></div>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import config from "../assets/js/wechatWorkConfig.js";

export default {
  computed: {
    ...mapState(["requestUrl", "token"]),

  },

  data() {
    return {
    };
  },

  mounted() {
    this.createWXQRCode()
  },

  onLoad() {
    if (this.token && this.token !== undefined) {
      this.toHomePage();
    }
  },

  methods: {
    ...mapMutations(["SET_URL", "LOGOUT", "LOGIN"]),
    // 使用企业微信静默登录
    // useWechat() {
    //   console.log("点击跳转企业微信登录页面");
    //   const wxconfig = config.getConfig();
    //   console.log(wxconfig);
    //   window.location.href =
    //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
    //     wxconfig.appid +
    //     "&redirect_uri=" +
    //     wxconfig.requestUrl +
    //     "%2f%23%2fwechatLogin&response_type=code&scope=snsapi_base&agentid=" +
    //     wxconfig.agentid +
    //     "&state=" +
    //     wxconfig.state +
    //     "#wechat_redirect";
    // },


    //企业微信扫码登录
    createWXQRCode() {
      const wxconfig = config.getConfig();

      new WwLogin({
        id: "qrCode",
        appid: wxconfig.appid,
        agentid: wxconfig.agentid,
        redirect_uri: encodeURIComponent(wxconfig.requestUrl + "/#/wechatLogin"),
        state: wxconfig.state,
        href: "",
        lang: "zh"
      })
    },

    //执行跳转
    toHomePage() {
      this.$router.push({ path: "/homePage" });
    },



  },
};
</script>

<style lang="stylus" scoped>
.container {
  height: 100vh - 22rpx;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.top {
  flex: 0 0 10%;
  width: 100%;
  text-align: center;
  margin-bottom: 200rpx;
}

.logo-icon {
  width: 100rpx;
  height: 100rpx;
  display: inline-block;
}

.logo-font {
  width: 250rpx;
  height: 100rpx;
  display: inline-block;
}

.form {
  flex: 0 0 30%;
  width: 100%;
  text-align: center;
}

.commonBtn {
  border: 1px solid #808080;
}
</style>
