<template>
  <div id="app">
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
// import { mapMutations } from "vuex";
// import env from "./assets/js/envChange.js";
export default {
  data() {
    return {};
  },

  mounted() {
    // 获取环境
    // const envData = env.getEnv();

    // console.log("显示环境链接" + envData);

    // this.SET_URL(envData);
  },

  methods: {
    // ...mapMutations(["SET_URL"]),
  },
};
</script>


<style lang="stylus">
@import '~@/assets/styles/fontAndColor.styl';
@import '~@/assets/styles/common.css';

// 设定窗口div撑开整个界面并居中，上下左右各留20px，注意body 和html也是height 100%
.main-body {
  min-height: calc(100vh - 96px);
  padding: 20px;
  background: #eeeef0;
}

.main-body-window {
  background: #fff;
  min-height: calc(100vh - 96px);
  boxShadow();
}

</style>
