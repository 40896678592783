import Vue from 'vue'
import Router from 'vue-router'
import cookie from '@/assets/js/cookie'
import loginPage from '../pages/index.vue'
import wechatLogin from '../pages/wechatLogin/wechatLogin.vue'
import homePage from '../pages/homePage/homePage.vue'


Vue.use(Router)// 在这里做路由守卫，拒绝未登录的链接请求

const router = new Router({

  routes: [
    {
      path: '/',
      name: 'loginPage',
      component: loginPage,
    },
    {
      path: '/wechatLogin',
      name: 'wechatLogin',
      component: wechatLogin,
    },
    {
      path: '/homePage',
      name: 'homePage',
      component: homePage,
      meta: {
        requireAuthStaff: false //待修改
      }
    }
  ]
})

// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {
  const token = cookie.getLocalStorage("token") // 改为由cookie是否存在来判断是否拦截
  if (to.meta.requireAuthStaff) { // 判断该路由是否需要登录权限
    if (token) {
      next()
    } else {
      router.push({ path: "/" });
    }
  }
  else {
    next()
  }
})

// router.afterEach((to, from) => {
//   // 通过config接口注入权限验证配置，我封装在了 vuex 里
//   $store.dispatch("handleWxConfig");
// });

/* 最后把router当做模块映射出去 */
export default router
