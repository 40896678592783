import Vue from 'vue'
import Vuex from 'vuex'
import cookie from '@/assets/js/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: cookie.getLocalStorage('token'),
  },
  mutations: {

		SET_URL(state, data) {
			state.requestUrl = data
			cookie.setLocalStorage('requesturl',data)
		},

		LOGIN(state, data) {
			state.token = data.token;
			cookie.setLocalStorage('token', data.token)
		},

		LOGOUT(state) {
			state.token = "";
			cookie.removeLocalStorage('token');
		},


    // handleWxConfig({ state }) {
    //   // 从接口获取微信签名，需要后端同学提供
    //   $apiCommon.getWxSign({
    //     url: location.href.slice(0, location.href.indexOf("#")) // 注意：location.href最后如果有'/'，必须保留
    //   }).then(res => {
    //         // 步骤二：通过config接口注入权限验证配置
    //         window.wx.config({
    //           beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题  
    //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
    //           appId: res.data.appId, // 必填，企业微信的corpID。公司的唯一标识‘ww20745c5e79cfa434’
    //           timestamp: new Date().getTime(), // 必填，生成签名的时间戳
    //           nonceStr: $utils.getRandomString(16), // 必填，生成签名的随机串，这里用的是自定义方法
    //           signature: res.data.sign, // 必填，签名，见附录-JS-SDK使用权限签名算法 https://work.weixin.qq.com/api/doc/90000/90136/90506
    //           jsApiList: ["previewFile"] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来。所有JS接口列表见附录2
    //         });
    //         // 步骤三：通过ready接口处理成功验证。没有需要立即调用的api，可以不写
    //         window.wx.ready(function() {});
    //         // 步骤四：通过error接口处理失败验证
    //         window.wx.error(function(err) {});
    //     })
    // }
    // SET_USERNAME(state, data) {
    //   state.userName = data
    //   cookie.setLocalStorage('username', data)
    // },

    // SET_AVATAR(state, data) {
    //   state.userAvatarUrl = data
    //   cookie.setLocalStorage('avatar', data)
    // },

    // SET_TOKEN(state, data) {
    //   state.token = data
    //   cookie.setLocalStorage('token', data)
    //   state.signInState = true
    // },

    // SET_GROUP(state, data) {
    //   state.groupid = data
    //   cookie.setLocalStorage('groupid', data)
    // },

    // LOGOUT: (state) => {
    //   // 登出的时候要清除token等数据
    //   state.userName = ""
    //   state.userAvatarUrl = ""
    //   state.signInState = false
    //   state.groupid = ""
    //   cookie.logout()
    // }
  },
  actions: {
  },
  modules: {
  }
})
