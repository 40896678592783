<template>
  <div class="flexCenterCenterInSpace">授权登录中……</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState([ "token"]),
  },

  mounted() {
    console.log("开始进行扫码登录验证")
    let code = this.getParam("code");
    console.log("获取企业微信参数为" + code);
    this.loginWechat(code);
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["SET_URL", "LOGOUT", "LOGIN"]),

    //地址栏截取参数方法
    getParam(key) {
      //假设地址栏url为http://www.xxx.com/test?name=zhangshan&age=50#hello;
      let url = window.location.href;
      console.log(url)
      let pattern = new RegExp("[?&]*" + key + "=([^&]+)");
      try {
        let value = url.match(pattern)[1];
        return value;
      } catch (err) {
        return undefined;ß
      }
    },

    loginWechat(code) {
      this.axios
        .post(
          "/api/login/wechatWork/console",
          {
            code,
          },
          { headers: {} }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "登录成功!",
            });
            this.handleLogin(
              res.data.token
            );
          } else {
            this.$message.error("登录失败" + res.data.message);
            this.$router.push({ path: "/" });
          }
        })
        .catch((err) => {
          this.$message.error("登录失败" + err.response.status);
          this.$router.push({ path: "/" });
        });

    },

    //执行登录操作
    handleLogin(token) {
      let data = { token };
      this.LOGIN(data);
      this.toHomePage();
    },

    //执行跳转
    toHomePage() {
      this.$router.push({ path: "/homePage" });
    },
  },
};
</script>

<style lang="stylus">
.flexCenterCenterInSpace {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
