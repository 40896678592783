import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "element-ui/lib/theme-chalk/display.css";
import './assets/styles/reset.css'
import './assets/styles/border.css'
import "babel-polyfill"
import VConsole from 'vconsole'
const vConsole = new VConsole()
Vue.use(vConsole)
console.info("vconsole-info-测试")
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.axios = Axios;

new Vue({
  router: router,
  store,
  render: h => h(App)
}).$mount('#app')
