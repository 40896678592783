function getCookie(c_name) {
    if (document.cookie.length > 0)//首先查询cookie是否是空的
    {
        let c_start = document.cookie.indexOf(c_name + "=")//检测这个cookie是否存在
        if (c_start != -1)//如果cookie存在
        {
            c_start = c_start + c_name.length + 1 //获取到cookie的值的开始位置
            let c_end = document.cookie.indexOf(";", c_start)//从c_start开始查找";"的存在
            if (c_end == -1) c_end = document.cookie.length//如果没找到，说明是最后一项
            return unescape(document.cookie.substring(c_start, c_end))//把cookie的值拆分出来并且对这个值进行解码，unescape()与escape()相对，对被escape()编码的字符串进行解码
        }
    }
    return ""//不存在就返回空
}

function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate + expiredays);
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
}


function logout() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的
            document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的
            document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的
        }
    }
    /*     window.location.replace("/"); */
}

function setLocalStorage(key,value){
    localStorage.setItem(key,value)
}

function getLocalStorage(key){
  return localStorage.getItem(key)
}

function removeLocalStorage(key){
    localStorage.removeItem(key);
}



export default {getCookie,setCookie,logout,setLocalStorage,getLocalStorage,removeLocalStorage};

/* function checkIdentify() {
    username = getCookie("username");
    if (username == null || username == "") {
        alert("返回首页登录！");
        window.location.replace("/");//如果用户还未登录，那么返回登录界面
    } else {
        identify = getCookie("identify");  //如果用户身份是管理员，直接跳转管理员页面
        if (identify == "admin") {
            alert("管理员身份登录，跳转后台！");
            window.location.replace("/backStage");
        }
    }
} */

