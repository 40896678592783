<template>
  <div class="main">
    <div class="main-left">
      <div class="main-left-logo"></div>

      <div class="main-left-title">{{ title }}</div>
    </div>

    <div class="main-menu">
      <el-menu
        router
        :default-active="$route.path"
        mode="horizontal"
        background-color="#464c5c"
        text-color="#8f929b"
        active-text-color="#fff"
      >
        <el-menu-item
          v-for="item in menuNameList"
          :key="item.index"
          :index="item.index"
          :disabled="item.disabled"
        >
          <i :class="item.iconName"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>

    <div class="main-right">

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "mainHeader",

  computed: {
    ...mapState({
      userAvatarUrl: "userAvatarUrl",
      userName: "userName",
    }),
  },

  data() {
    return {
      // 编辑菜单显示
      title: "青浦协和照片管理中心",
      menuNameList: [
        {
          iconName: "el-icon-tickets",
          index: "/homePage",
          title: "列表",
          disabled: false,
        }
      ],
    };
  },

  methods: {
    ...mapMutations(["LOGOUT"]),

    // logout() {
    //   this.LOGOUT();
    //   window.location.href =
    //     "https://v.campus.qq.com/application.html#/appManage";
    // },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.el-menu--horizontal>.el-menu-item {
  height: 56px;
  line-height: 56px;
}

.el-menu-item.is-active {
  border-bottom-color: $headerBackgroundColor !important;
}

.main {
  height: 56px;
  line-height: 56px;
  display: flex;
  background-color: $headerBackgroundColor !important;
  color: $headerFontColor;
  justify-content: space-between;
  flex-direction: row;
}

.main-left {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

// 圆形的logo
.main-left-logo {
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
  background: #fff;
  background-image: url('~@/assets/static/image/app_logo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100%;
}

.main-right {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.main-right-avatar {
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.main-right-avatar-img {
  height: 100%;
  width: 100%;
}
</style>
