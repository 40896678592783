function getConfig(){

	const config = {
	  appid: 'wwd806c1b8d6ef5502',
	  agentid: '1000191',
	  requestUrl: "http://avatar.suisqp.cn",
	  state:'suis1000191'
	};
	
	return config
}

export default { getConfig }