<template>
  <div class="main">
    <!-- 按钮组 -->
    <div class="main-btn">

      <el-radio-group v-model="recordOfRole" class="role-radio">
        <el-radio label="student">学生</el-radio>
        <el-radio label="teacher">教职工</el-radio>
      </el-radio-group>

      <el-cascader class="createBtn" :props="props" v-model="searchName" placeholder="人员选择" :options="option"
        :show-all-levels="false" filterable collapse-tags>
      </el-cascader>

      <el-button size="medium" type="success" class="createBtn" @click="doSeatch()" icon="el-icon-search">查询</el-button>
      <div v-if="this.list.length > 0">
        <el-button size="medium" type="success" class="createBtn" @click="downloadAvatar()" icon="el-icon-download"
          v-if="recordOfRole == 'student'">导出头像 </el-button>

        <el-button size="medium" type="success" class="createBtn" @click="downloadFaceImg()" icon="el-icon-download">
          导出人脸照
        </el-button>
      </div>

    </div>

    <!-- 表格部分 -->
    <div class="main-form-div">
      <el-table border v-loading="loading" :data="
        this.list.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      " tooltip-effect="dark" style="width: 100%">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" width="55" label="序号"></el-table-column>

        <el-table-column v-if="recordOfRole == 'student'" prop="userNumber" label="学号"></el-table-column>

        <el-table-column v-else prop="userNumber" label="工号"></el-table-column>

        <el-table-column prop="name" label="姓名"></el-table-column>

        <el-table-column label="头像" v-if="recordOfRole == 'student'">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar" :src="scope.row.avatar" class="avatar" />
            <div v-else>没有头像</div>
          </template>
        </el-table-column>

        <el-table-column label="人脸照片">
          <template slot-scope="scope">
            <img v-if="scope.row.faceImg" :src="scope.row.faceImg" class="avatar" />
            <div v-else>没有照片</div>
          </template>
        </el-table-column>

      </el-table>

      <el-pagination style="margin: 20px" background layout="total, prev, pager, next, sizes"
        @current-change="handleCurrentChange" :total="list.length" :page-size="pageSize" :current-page="currentPage"
        @size-change="handleSizeChange"></el-pagination>
    </div>

  </div>
</template>


<script>
export default {
  name: "photoList",

  mounted() {
    this.getOption();
  },

  watch: {
    // 监听radio选项的值，通过不同的值获取不同的数据
    recordOfRole: function () {
      this.getOption();
      this.ids = [];
      this.list = []
      this.searchName = []
      this.currentPage = 1
    }
  },

  data() {
    return {
      // 表单数据
      recordOfRole: "student",
      searchName: [],
      loading: false,
      currentPage: 1 /* 当前页页码 */,
      pageSize: 10 /* 每页显示的条数 */,
      list: [],
      ids: [],
      option: [],
      props: { multiple: true },

    };
  },

  methods: {

    // 通过姓名进行搜索
    doSeatch() {
      this.ids = []
      this.searchName.forEach(arr => {
        this.ids.push(arr[arr.length - 1])
      })
      console.log(this.ids)
      this.getList();
    },

    getOption() {
      this.loading = true
      this.axios
        .get(
          "/api/personnel_information/search",
          {
            params: { dataRole: this.recordOfRole },
            headers: { token: this.$store.state.token },
          }
        )
        .then((res) => {
          this.option = res.data.data;
          this.loading = false
        });
    },

    // 页码器的函数
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },

    getList() {
      this.loading = true;
      this.axios
        .post(
          "/api/personnel_photo_list/search",
          {
            ids: this.ids,
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.list = []
            this.list = res.data.data;
            this.loading = false;
          } else {
            this.$message.error("数据获取失败：" + res.data.message);
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
          this.loading = false;
        });
    },

    downloadAvatar() {
      this.$confirm("确认导出吗?", "提交确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestToDownload("avatar");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    downloadFaceImg() {
      this.$confirm("确认导出吗?", "提交确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestToDownload("faceImg");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    requestToDownload(downloadType) {

      const loading = this.$loading({
        lock: true,
        text: "数据处理中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .post(
          `/api/${this.recordOfRole}/${downloadType}/download`,
          {
            ids: this.ids
          },
          { headers: { token: this.$store.state.token } }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "导出成功!",
            });
            window.open(res.data.data);
            loading.close();
          } else {
            this.$message.error("导出失败：" + res.data.message);
            this.$loading = false;
          }
        })
        .catch((err) => {
          this.$message.error("服务错误：" + err.response.status);
          loading.close();
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';

.main-btn {
  margin: 20px;
  position: relative;
}

.createBtn {
  margin: 20px;
}

.main-form-div {
  margin: 20px;
}

.role-radio {
  margin: 20px;
}

.dialogWindow p {
  color: #909399;
}

.moreSituation-div {
  padding: 10px;
}

.moreSituation-div:hover {
  boxShadow();
}

.btn-group {
  width: 400px;
  position: absolute;
  right: 1px;
  top: 20px;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.avatar-uploader :hover {
  border-color: #409EFF;
}

.el-upload :hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>