<template>
  <div class="main">
    <main-header></main-header>

    <div class="main-body">
      <div class="main-body-window">
        <!-- 在这里进行动态组件注册 -->
        <activity-form></activity-form>
      </div>
    </div>
  </div>
</template>

<script>
import mainHeader from "@/pages/common/header.vue";
import activityForm from "./components/photoList.vue";

export default {
  name: "homePage",
  components: {
    mainHeader,
    activityForm
  },

};
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/fontAndColor.styl';


</style>